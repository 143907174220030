require("./main.scss");
require("awesomplete");
const cities = require("../server/data/cities.json");
const getLocale = require("../server/locale");

window.app = {
    start: function () {
        let locality =  document.getElementById("locality");
        let name = document.getElementById("name");
        //create unique field name for locality to disable autocomplete crossbrowser so it won't overlap the awesomplete
        locality.setAttribute("name",locality.name + "-" + new Date().getTime().toString());
        const optionList = document.getElementById("optionList");
        this.searchType = optionList.value;
        this.autoComplete();
        optionList.addEventListener("change", (e) => this.onChange(e));
        document.getElementById("searchform").addEventListener("submit", (e) => this.searchRealtor(e));
        name.addEventListener("invalid", this.onInvalidName);
        name.addEventListener("input", this.checkValidityName);
        locality.addEventListener("invalid", this.onInvalidLocality);
        locality.addEventListener("input", this.turnOfCustomValidity);
    },

    onChange: function (e) {
        this.searchType = e.currentTarget.value;
        const locality = document.getElementById("locality");
        const name = document.getElementById("name");
        if (this.searchType === "gemeente") {
            locality.style.display = "block";
            name.style.display = "none";
            locality.removeAttribute("disabled");
            name.setAttribute("disabled", true);
            name.setAttribute("required", false);
        } else {
            locality.style.display = "none";
            name.style.display = "block";
            name.removeAttribute("disabled");
            locality.setAttribute("disabled", true);
            name.setAttribute("required", true);
        }
    },
    autoComplete: function () {
        const locale = getLocale(document.location.href);
        const inputList = [];
        Object.values(cities).forEach(obj => {
            const rJson = {};
            const slug = (obj[locale]).toLowerCase();
            rJson["label"] = obj['pc'] + ' ' + obj[locale];
            rJson["value"] = obj['pc'] + '-' + slug;
            inputList.push(rJson);
        });
        const input = document.getElementById("locality");
        input.addEventListener("awesomplete-select", (event) => {
            if (event?.text?.value) {
                window.location.href = `/${this.fixedEncodeURIComponent(event?.text?.value)}/`;
            }
        })
        new Awesomplete(input, {
            list: inputList,
            autoFirst: true,
            sort: (a, b) => {
                if ( a.label < b.label ){
                    return -1;
                }
                if ( a.label > b.label ){
                    return 1;
                }
                return 0;
            },
            filter: function (text, input) {
                return (isNaN(parseInt(input, 10))) ?
                    Awesomplete.FILTER_CONTAINS(text, input) :
                    Awesomplete.FILTER_STARTSWITH(text, input)
            }
        });
    },
    searchRealtor: function (e) {
        e.preventDefault();
        const locale = getLocale(document.location.href);
        const locality = document.getElementById('locality');

        if (optionList.value === "gemeente") {
            const slug = document.getElementById("locality").value;
            let newSlug = false;

            //check if slug matches desired slug pattern
            /*if (!slug.match("[1-9]{1}[0-9]{3}-[a-z]*")) {
                //check if slug matches postal code pattern
                if (slug.match("[1-9]{1}[0-9]{3}")) {
                    //check if slug matches actual postal code
                    newSlug = app.compareSlugValue(slug, "pc");
                } else {
                    //check if slug matches actual city name
                    newSlug = app.compareSlugValue(slug, "name");
                }
            } else {
                //check if slug matches list
                newSlug = app.compareSlugValue(slug);
            }

            if(!newSlug) {
                //no correct match was found
                locality.setCustomValidity("Er werd geen exact resultaat gevonden. Geef gemeente of postcode in en selecteer daarna uit de lijst.");
                locality.reportValidity();
            } else {
                //there is a match, redirect
                window.location.href = "/" + newSlug;
            }*/
            window.location.href = `/${this.fixedEncodeURIComponent(slug)}/`;
        }
        if (this.searchType === "naam") {
            const slug = document.getElementById("name").value;
            const url = locale === "fr" ? `/recherche/${this.fixedEncodeURIComponent(slug)}/` : `/zoek/${this.fixedEncodeURIComponent(slug)}/`;
            window.location.href = url;
        }
        return false;
    },

    fixedEncodeURIComponent: function (str) {
        return encodeURIComponent(str);
    },

    compareSlugValue: function (slug, compareWith = "slug") {
        let match = 0;
        let newSlug = "";
        let tempSlug = "";
        if (compareWith == "pc" ||  compareWith == "name") {
            Object.values(cities).forEach(obj => {
                if (slug.toLowerCase() == obj[compareWith].toLowerCase()) {
                    match++;
                    newSlug = obj['pc'] + '-' + obj['slug'];
                }
            });
        } else {
             Object.values(cities).forEach(obj => {
                tempSlug = obj['pc'] + '-' + obj['slug'];
                if (slug.toLowerCase() == tempSlug.toLowerCase()) {
                    match++;
                    newSlug = obj['pc'] + '-' + obj['slug'];
                }
             });
        }
        //check is there is exactly one match
        if (match==1) {
            return newSlug;
        }

        return false;
    },
    onInvalidName: function (e) {
        const locale = getLocale(document.location.href);
        if(document.getElementById("name").value.length == 0) {
            const message = locale === "fr" ? "Le nom est un champ obligatoire." : "Naam is verplicht.";
            e.target.setCustomValidity(message);
        } else {
            const message = locale === "fr" ? "Veuillez utiliser des caractères valides." : "Gelieve enkel geldige karakters te gebruiken.";
            e.target.setCustomValidity(message);
        }
    },
    checkValidityName: function (e) {
        const pattern = e.target.pattern;
        const value = e.target.value;
        if (value.match(pattern)) {
            window.app.turnOfCustomValidity(e);
        } else {
            window.app.onInvalidName(e);
        }
    },

    onInvalidLocality: function (e) {
        const locale = getLocale(document.location.href);
        const message = locale === "fr" ? "Aucun résultat exact n'a été trouvé. Entrez la municipalité ou le code postal, puis sélectionnez dans la liste." :
            "Er werd geen exact resultaat gevonden. Geef gemeente of postcode in en selecteer daarna uit de lijst.";
        e.target.setCustomValidity(message);
    },

    turnOfCustomValidity: function (e) {
        e.target.setCustomValidity("");
    }
};
